import React from "react";
import { SiGithub } from "react-icons/si";
function Project() {
  const highlightStyle = {
    color: "#346751",
    fontWeight: "bold",
  };
  return (
    <div>
      <h3 style={{ marginLeft: "80px", fontSize: "40px" }}>Projects</h3>
      <div className="projects-w">
        <div className="project-t">
          <h3 className="project-title">ITSIG</h3>
          <p className="project-info">
            Developed using the{" "}
            <span className="neon-underline">MERN stack</span> and{" "}
            <span className="neon-background">AntDesign</span>, this web
            application manages Co-Curricular Activity (CCA) members, featuring{" "}
            <span className="neon-underline">dual user portals</span>,{" "}
            <span className="neon-underline">secure login</span> with{" "}
            <span className="neon-underline">2-factor authentication</span>, and{" "}
            <span className="neon-underline">JWT session management</span>. It
            supports member management tasks like adding, editing, and removing
            details, incorporates{" "}
            <span className="neon-underline">automated email responses</span>,
            and uses <span className="neon-underline">AWS S3</span> for image
            storage with a{" "}
            <span className="neon-underline">drag-and-drop interface</span>.{" "}
            <span className="neon-underline">Test cases</span> were thoroughly
            implemented for both frontend and backend to ensure functionality
            and reliability.
          </p>
          <div className="project-tech">
            <p className="react">#react</p>
            <p className="mongodb">#mongodb</p>
            <p className="aws">#AWS S3</p>
            <p className="antdesign">#AntDesign</p>
            <p className="nodejs">#NodeJS</p>
            <p className="nodejs">#Cypress</p>
          </div>
          <div className="project-buttons">
            <a href="https://github.com/SwanHtetAungX/FWEB_Project">
              <button className="project-button">
                <SiGithub /> Github
              </button>
            </a>

            <button className="project-button">🕹️ Demo</button>
            <button className="project-button"> 👀 View More</button>
          </div>
        </div>
        <div className="project-t">
          <h3 className="project-title">SayNoWaste</h3>
          <p className="project-info">
            This mobile app enhances sustainability by enabling users to{" "}
            <span class="neon-underline">track food expiry dates</span>, receive{" "}
            <span class="neon-underline">reminders</span>,{" "}
            <span class="neon-underline">share surplus food</span> with others,
            and manage shopping to{" "}
            <span class="neon-underline">avoid duplicate purchases</span>. It
            features secure{" "}
            <span class="neon-underline">
              login options, including email and Google
            </span>
            , <span class="neon-underline">notifications</span> for items
            nearing expiry,{" "}
            <span class="neon-underline">in-app chat functionalities</span> for
            community interaction, and{" "}
            <span class="neon-underline">effective inventory management</span>{" "}
            tools. Designed to{" "}
            <span class="neon-underline">reduce food waste</span> and promote{" "}
            <span class="neon-underline">eco-conscious living</span>, this app
            serves as a comprehensive solution for managing and optimizing
            household food resources.
          </p>
          <div className="project-tech">
            <p className="react">#Flutter</p>
            <p className="mongodb">#Dart</p>
            <p className="aws">#Firebase</p>
          </div>
          <div className="project-buttons">
            <a href="https://github.com/SwanHtetAungX/SayNoWaste">
              <button className="project-button">
                <SiGithub /> Github
              </button>
            </a>

            <button className="project-button">🕹️ Demo</button>
            <button className="project-button"> 👀 View More</button>
          </div>
        </div>

        <div className="project-t">
          <h3 className="project-title">Loan Approval Predictor</h3>
          <p className="project-info">
            This website leverages{" "}
            <span class="neon-underline">machine learning</span> to predict loan
            approval outcomes. It allows users to{" "}
            <span class="neon-underline">input their details</span> and utilizes
            the{" "}
            <span class="neon-underline">Logistic Regression Algorithm</span> to
            determine whether a loan will be approved or not, providing a
            valuable tool for pre-application insights.
          </p>
          <div className="project-tech">
            <p className="pink">#scikit-learn</p>
            <p className="react">#Python</p>
            <p className="aws">#Flask</p>
          </div>
          <div className="project-buttons">
          <a href="https://github.com/SwanHtetAungX/Loan-Predictor">
              <button className="project-button">
                <SiGithub /> Github
              </button>
            </a>
            <button className="project-button">🕹️ Demo</button>
            <button className="project-button"> 👀 View More</button>
          </div>
        </div>

        <div className="project-t">
          <h3 className="project-title">StudyTaskade</h3>
          <p className="project-info">
            A comprehensive productivity platform developed using{" "}
            <span class="neon-underline">AWS</span>, this application features a{" "}
            <span class="neon-underline">study timer</span>,{" "}
            <span class="neon-underline">task manager</span>,{" "}
            <span class="neon-underline">discussion forum</span>, and{" "}
            <span class="neon-underline">robust authentication system</span>.
            The platform is built using a{" "}
            <span class="neon-underline">microservices architecture</span> to
            enhance <span class="neon-underline">scalability</span> and{" "}
            <span class="neon-underline">maintainability</span>.
          </p>
          <div className="project-tech">
            <p className="react">#html</p>
            <p className="pink">#css</p>
            <p className="aws">#javascript</p>
            <p className="mongodb">
              #AWS API Gateway, Cognito, CloudFront, Lambdas, S3, DynamoDB and
              RDS
            </p>
          </div>
          <div className="project-buttons">
          <a href="https://github.com/SwanHtetAungX/Study-Taskade">
              <button className="project-button">
                <SiGithub /> Github
              </button>
            </a>
            <button className="project-button">🕹️ Demo</button>
            <button className="project-button"> 👀 View More</button>
          </div>
        </div>

        <div className="project-t">
          <h3 className="project-title">BeeHive Monitoring IOT</h3>
          <p className="project-info">
            an <span class="neon-underline">IoT solution</span> for beekeepers
            to remotely monitor and control beehives. Equipped with{" "}
            <span class="neon-underline">
              temperature, humidity, weight, acoustic, and image sensors
            </span>
            , it transmits data to <span class="neon-underline">AWS Cloud</span>
            . Data is accessible in real-time via{" "}
            <span class="neon-underline">mobile</span> with interactive charts,
            allowing beekeepers to make necessary adjustments to the hive
            environment.
          </p>
          <div className="project-tech">
            <p className="pink">#css</p>
            <p className="react">#html</p>
            <p className="aws">#chart.js</p>
            <p className="mongodb">
              #AWS IOT Core,IOT Rule,S3,Lambda,API gateway,SNS,DynamoDB,Athena
            </p>
          </div>
          <div className="project-buttons">
          <a href="https://github.com/SwanHtetAungX/BeeHive-IOT">
              <button className="project-button">
                <SiGithub /> Github
              </button>
            </a>
            <button className="project-button">🕹️ Demo</button>
            <button className="project-button"> 👀 View More</button>
          </div>
        </div>

        <div className="project-t">
          <h3 className="project-title">FooDo</h3>
          <p className="project-info">
            a restaurant review website that allows users to explore and give
            reviews that.This website features user-friendly navigation to
            search for restaurants by location, cuisine, or rating and{" "}
            <spam className="neon-underline">GOOGLE MAP API</spam>.
          </p>
          <div className="project-tech">
            <p className="pink">#css</p>
            <p className="react">#html</p>
            <p className="aws">#javascript</p>
            <p className="mongodb">#MySQL</p>
            <p className="antdesign">#Node.js, Express.js</p>
          </div>
          <div className="project-buttons">
          <a href="https://github.com/SwanHtetAungX/Foodo-a-restaurant-review-website">
              <button className="project-button">
                <SiGithub /> Github
              </button>
            </a>
            <button className="project-button">🕹️ Demo</button>
            <button className="project-button"> 👀 View More</button>
          </div>
        </div>

        <div className="project-t">
          <h3 className="project-title">BudgetBuddy</h3>
          <p className="project-info">
            a simplebudget tracking website to help users manage their finances.
            It provides tools for monitoring daily income and expenses, allowing
            users to maintain a clear overview of their financial health.
            Developed as a practical project to facilitate learning in{" "}
            <span className="neon-underline">DevOps</span>, this platform
            incorporates continuous integration and deployment practices.
          </p>
          <div className="project-tech">
            <p className="pink">#css</p>
            <p className="react">#html</p>
            <p className="aws">#javascript</p>
            <p className="mongodb">
              #DevOps - Jenkins, Docker, Kubernetes, Terraform, Github, Mocha,
              Istanbul(NYC), Cypress
            </p>
          </div>
          <div className="project-buttons">
          <a href="https://github.com/chunkangso/DVOPS-Group-1">
              <button className="project-button">
                <SiGithub /> Github
              </button>
            </a>
            <button className="project-button">🕹️ Demo</button>
            <button className="project-button"> 👀 View More</button>
          </div>
        </div>

        <div className="project-t">
          <h3 className="project-title">MyDearReminder</h3>
          <p className="project-info">
            a Telegram bot developed for{" "}
            <span className="neon-underline">Hack&Roll 2023</span>, providing
            real-time bus arrival timings and notifications using{" "}
            <span className="neon-underline">
              Singapore's LTA Datamall API and the Telegram Bot API
            </span>
            . Users can save favorite bus stops and receive updates directly
            through Telegram
          </p>
          <div className="project-tech">
            <p className="react">#python</p>
          </div>

          <div className="project-buttons">
          <a href="https://github.com/SwanHtetAungX/MyDearReminder">
              <button className="project-button">
                <SiGithub /> Github
              </button>
            </a>
            <button className="project-button">🕹️ Demo</button>
            <button className="project-button"> 👀 View More</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Project;
