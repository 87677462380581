import React, { useState } from "react";
import { useMediaQuery } from 'react-responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

const NavBar = () => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <nav className={`navbar ${isNavExpanded ? 'expanded' : ''}`}>
      {isMobile ? (
        <>
          <button onClick={() => setIsNavExpanded(!isNavExpanded)} className="nav-toggle">
            <FontAwesomeIcon icon={isNavExpanded ? faTimes : faBars} />
          </button>
          <div className={`nav-menu ${isNavExpanded ? 'show' : ''}`}>
            <a href="#about" className="nav-item">About</a>
            <a href="#projects" className="nav-item">Projects</a>
            {/* <a href="#services" className="nav-item">Services</a> */}
            <a href="#achievements" className="nav-item">Achievements</a>
            <a href="#contact" className="nav-item">Contact</a>
          </div>
        </>
      ) : (
        <>
          <a href="#about" className="nav-item">About</a>
          <a href="#projects" className="nav-item">Projects</a>
          {/* <a href="#services" className="nav-item">Services</a> */}
          <a href="#achievements" className="nav-item">Achievements</a>
          <a href="#contact" className="nav-item">Contact</a>
        </>
      )}
    </nav>
  );
};

export default NavBar;
