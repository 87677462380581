import React, { useState } from "react";
import {
  DiJavascript1,
  DiPython,
  DiJava,
  DiReact,
  DiNodejs,
  DiMysql,
} from "react-icons/di";
import {
  SiAnaconda,
  SiPowerbi,
  SiTypescript,
  SiTailwindcss,
  SiExpress,
  SiTerraform,
  SiFlutter,
  SiBootstrap,
  SiMongodb,
  SiFirebase,
  SiAmazondynamodb,
  SiFigma,
  SiAdobexd,
  SiCanva,
  SiJenkins,
  SiGithub,
  SiDocker,
  SiCypress,
  SiJest,
  SiMocha,
  SiVisualstudiocode,
} from "react-icons/si";
import Knime from "../images/knime.png";
function About() {
  const [activeTab, setActiveTab] = useState("skills");

  return (
    <div>
      <h3 className="about-me-header">About Me</h3>

      <p className="about-me">
        Hello! I'm a passionate year 3 student at{" "}
        <b style={{ backgroundColor: "#34373A" }}>Temasek Polytechnic(TP)</b>,
        specializing in <i>Information Technology</i>. My journey into the world
        of technology started young, with a keen interest in coding that has
        only grown stronger over the years. This passion has led me to pursue my
        education in Information Technology, where I'm deepening my knowledge
        and skills in programming, web development, and more. <br />
        <br />
        In addition to my academic pursuits, I'm also an active freelance web
        developer. This allows me to apply what I've learned in real-world
        projects, further honing my skills and contributing to the digital
        landscape. My freelance work has not only given me practical experience
        but also allowed me to understand the needs of clients and the
        importance of delivering solutions that meet their goals. <br />
        <br />
        One of my most cherished hobbies is{" "}
        <a
          href="https://en.wikipedia.org/wiki/Competitive_programming"
          style={{ textDecoration: "none" }}
        >
          <b style={{ backgroundColor: "#34373A" }}>competitive programming</b>
        </a>
        . It's not just about solving puzzles but also about pushing my limits
        and improving my problem-solving abilities. This passion for coding and
        problem-solving extends beyond my academic and professional life, making
        it a significant part of who I am. It began as a hobby; it became a
        passion and now, it's shaping up to be an exciting career. I'm eager to
        see where this path will take me and am looking forward to the
        innovations I can contribute to the ever-evolving tech landscape.
        <br />
        <br />
        I'm always eager to take on new challenges, whether it's in my studies,
        in my freelance projects, or in my competitive programming endeavors. My
        goal is to leverage my skills and passion to create impactful solutions
        and contribute to the technological advancements of the future.
      </p>

      <div className="tabs">
        <button
          onClick={() => setActiveTab("skills")}
          className={activeTab === "skills" ? "active" : ""}
        >
          Skills
        </button>
        <button
          onClick={() => setActiveTab("education")}
          className={activeTab === "education" ? "active" : ""}
        >
          Education
        </button>
        <button
          onClick={() => setActiveTab("experience")}
          className={activeTab === "experience" ? "active" : ""}
        >
          Experience
        </button>
      </div>

      {activeTab === "skills" && (
        <div className="content">
          <p
            style={{
              marginLeft: "80px",
              marginBottom: "20px",
              marginTop: "40px",
            }}
          >
            Programming Languages
          </p>
          <div className="skills-content">
            <div className="skill">
              <SiTypescript size={48} color="#3178C6" />
              <span>TypeScript</span>
            </div>
            <div className="skill">
              <DiJavascript1 size={48} color="#F7DF1E" />
              <span>JavaScript</span>
            </div>
            <div className="skill">
              <DiPython size={48} color="#3776AB" />
              <span>Python</span>
            </div>
            <div className="skill">
              <DiJava size={48} color="#007396" />
              <span>Java</span>
            </div>
          </div>

          <p
            style={{
              marginLeft: "80px",
              marginBottom: "20px",
              marginTop: "40px",
            }}
          >
            Framework & Libaries
          </p>
          <div className="skills-content">
            <div className="skill">
              <DiReact size={48} color="#3178C6" />
              <span>React.js</span>
            </div>
            <div className="skill">
              <SiTailwindcss size={48} color="#F7DF1E" />
              <span>TailWind CSS</span>
            </div>
            <div className="skill">
              <SiExpress size={48} color="#3776AB" />
              <span>Express.js</span>
            </div>
            <div className="skill">
              <SiFlutter size={48} color="#007396" />
              <span>Flutter</span>
            </div>

            <div className="skill">
              <SiBootstrap size={48} color="#007396" />
              <span>Bootstrap</span>
            </div>

            <div className="skill">
              <DiNodejs size={48} color="#007396" />
              <span>Node.js</span>
            </div>
          </div>

          <p
            style={{
              marginLeft: "80px",
              marginBottom: "20px",
              marginTop: "40px",
            }}
          >
            Databases
          </p>
          <div className="skills-content">
            <div className="skill">
              <DiMysql size={48} color="#3178C6" />
              <span>MySQL</span>
            </div>
            <div className="skill">
              <SiMongodb size={48} color="#F7DF1E" />
              <span>MongoDB</span>
            </div>
            <div className="skill">
              <SiFirebase size={48} color="#3776AB" />
              <span>Firebase</span>
            </div>
            <div className="skill">
              <SiAmazondynamodb size={48} color="#007396" />
              <span>AWS DynamoDB</span>
            </div>
          </div>

          <p
            style={{
              marginLeft: "80px",
              marginBottom: "20px",
              marginTop: "40px",
            }}
          >
            Design Tools
          </p>
          <div className="skills-content">
            <div className="skill">
              <SiFigma size={48} color="#3178C6" />
              <span>Figma</span>
            </div>
            <div className="skill">
              <SiAdobexd size={48} color="#F7DF1E" />
              <span>Adobe XD</span>
            </div>
            <div className="skill">
              <SiCanva size={48} color="#3776AB" />
              <span>Canva</span>
            </div>
          </div>

          <p
            style={{
              marginLeft: "80px",
              marginBottom: "20px",
              marginTop: "40px",
            }}
          >
            CI & CD
          </p>
          <div className="skills-content">
            <div className="skill">
              <SiJenkins size={48} color="#3178C6" />
              <span>Jenkins</span>
            </div>
            <div className="skill">
              <SiGithub size={48} color="#F7DF1E" />
              <span>Github</span>
            </div>
            <div className="skill">
              <SiTerraform size={48} color="#3776AB" />
              <span>Terraform</span>
            </div>
            <div className="skill">
              <SiDocker size={48} color="#3776AB" />
              <span>Docker</span>
            </div>
            <div className="skill">
              <SiCypress size={48} color="#3776AB" />
              <span>Cypress</span>
            </div>
            <div className="skill">
              <SiJest size={48} color="#3776AB" />
              <span>Jest</span>
            </div>
            <div className="skill">
              <SiMocha size={48} color="#3776AB" />
              <span>Mocha</span>
            </div>
          </div>

          <p
            style={{
              marginLeft: "80px",
              marginBottom: "20px",
              marginTop: "40px",
            }}
          >
            Tools
          </p>
          <div className="skills-content">
            <div className="skill">
              <SiVisualstudiocode size={48} color="#F7DF1E" />
              <span>VS Code</span>
            </div>

            <div className="skill">
              <SiAnaconda size={48} color="#F7DF1E" />
              <span>Anaconda</span>
            </div>

            <div className="skill">
              <img src={Knime} style={{ width: "51px", height: "51px" }} />
              <span>Knime</span>
            </div>
            <div className="skill">
              <SiPowerbi size={48} color="#3776AB" />
              <span>PowerBI</span>
            </div>
          </div>
        </div>
      )}

      {activeTab === "education" && (
        <div className="content">
          <ul>
            <li style={{ marginLeft: "80px", marginTop: "40px" }}>
              <p
                style={{
                  color: "#FF004F",
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
              >
                Diploma in Information Technology
              </p>
              <p
                style={{ opacity: "0.5", fontSize: "13px", marginTop: "20px" }}
              >
                Temasek Polytechnic(TP)
              </p>
              <i>
                <p
                  style={{
                    opacity: "0.5",
                    fontSize: "13px",
                    marginTop: "20px",
                  }}
                >
                  2022 - current{" "}
                </p>
              </i>
            </li>
          </ul>
        </div>
      )}

      {activeTab === "experience" && (
        <div className="content">
          <ul>
            <li style={{ marginLeft: "80px", marginTop: "60px" }}>
              {/* <p
                style={{
                  color: "#FF004F",
                  fontWeight: "bold",
                  fontSize: "20px",
                }}
              >
                Looking For Internship Opportunity
              </p> */}
              <div class="job">
                <h3
                  style={{
                    color: "#FF004F",
                    fontWeight: "bold",
                    fontSize: "20px",
                  }}
                >
                  RPA Engineer Intern
                </h3>

                <a
                  href="https://www.linkedin.com/company/health-promotion-board/"
                  target="_blank"
                  style={{textDecoration:'none'}}
                >
                  <i><p style={{marginTop:'5px',opacity:'0.7'}}>
                  <strong >Health Promotion Board</strong> - Singapore
                </p>
                </i>
                </a>
                
                <p style={{marginTop:'3px',opacity:'0.5'}}>September 2024 - Present</p>
                <p style={{marginTop:'5px',opacity:'0.5',fontSize:'15px'}}>
                  As an RPA Engineer intern, I am working on automating business
                  processes and improving operational efficiency at the Health
                  Promotion Board. I focus on developing, testing, and deploying
                  RPA solutions to streamline workflows.
                </p>
                
              </div>
              <br></br>
              <p
                style={{
                  marginTop: "20px",
                  opacity: "0.5",
                  fontFamily: '"Josefin Sans", sans-serif',
                }}
              >
                Check Out My Resume
              </p>
              <button class="button" style={{ marginTop: "15px" }}>
                <span class="button_lg">
                  <span class="button_sl"></span>
                  <span
                    class="button_text"
                    style={{ fontFamily: '"Josefin Sans", sans-serif' }}
                  >
                    Download Now
                  </span>
                </span>
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default About;
