import React from "react";
import TP from "../images/tp.png";
import PIOI from "../images/pioi.png";
import PSM from "../images/PSM.png";
import UDEMY from "../images/udemy.png";
import GOOGLECLOUD from "../images/googlecloud.png";


function Achievements() {
    return (
      <div>
        <h3 className="achievements-header">Achievements</h3>
        <div className="achievements-w">
          <div className="achievements-i">
            <img src={TP} style={{ width: "150px", height: "80px" }} />
            <div className="text-container">
              <a href="#achievements" style={{ textDecoration: 'none', color: 'inherit' }}>
                <h5 style={{ cursor: 'pointer' }} onMouseOver={e => e.currentTarget.style.textDecoration = 'underline'}
                  onMouseOut={e => e.currentTarget.style.textDecoration = 'none'}>Director's List Award For AY 22-23</h5>
              </a>
              <p className="achievements-details">
                Awarded to Top 10% of the cohort
              </p>
            </div>
          </div>
  
          <div className="achievements-i">
            <img src={PIOI} style={{ width: "150px", height: "80px" }} />
            <div className="text-container">
              <a href="#achievements" style={{ textDecoration: 'none', color: 'inherit' }}>
                <h5 style={{ cursor: 'pointer' }} onMouseOver={e => e.currentTarget.style.textDecoration = 'underline'}
                  onMouseOut={e => e.currentTarget.style.textDecoration = 'none'}>Silver Medal 🥈</h5>
              </a>
              <p className="achievements-details">
                PIOI (Polytechnic-ITE Olympiad in Informatics) is a 5-hour
                informatics Olympiad open to all polytechnic and ITE students. It
                focuses on testing problem-solving skills as well as data
                structures and algorithms knowledge. During the contest,
                contestants will be given problem statements which they have to
                solve by writing efficient code. Contestants will then submit
                their solutions to the grading system, which will automatically
                test their code with test cases and award points to them.
              </p>
            </div>
          </div>
  
          <div className="achievements-i">
            <img src={PSM} style={{ width: "150px", height: "80px" }} />
            <div className="text-container">
              <a href="https://www.credly.com/badges/e221483c-85ff-4c98-a0a8-ea799a6d3938/" style={{ textDecoration: 'none', color: 'inherit' }}>
                <h5 style={{ cursor: 'pointer' }} onMouseOver={e => e.currentTarget.style.textDecoration = 'underline'}
                  onMouseOut={e => e.currentTarget.style.textDecoration = 'none'}>Professional Scrum Master Certificate (PSM I)</h5>
              </a>
            </div>
          </div>
  
          <div className="achievements-i">
            <img src={UDEMY} style={{ width: "150px", height: "80px" }} />
            <div className="text-container">
              <a href="https://www.udemy.com/certificate/UC-93fe94e4-5648-4331-ba0a-f4e0998c4fd7/" style={{ textDecoration: 'none', color: 'inherit' }}>
                <h5 style={{ cursor: 'pointer' }} onMouseOver={e => e.currentTarget.style.textDecoration = 'underline'}
                  onMouseOut={e => e.currentTarget.style.textDecoration = 'none'}>The Complete Web Developer 2023 BootCamp Certification</h5>
              </a>
            </div>
          </div>
  
          <div className="achievements-i">
            <img src={GOOGLECLOUD} style={{ width: "150px", height: "80px" }} />
            <div className="text-container">
              <a href="https://drive.google.com/file/d/1aQP5JM_-c8WnoYNeiX6HrJxSKtM3g9H4/view" style={{ textDecoration: 'none', color: 'inherit' }}>
                <h5 style={{ cursor: 'pointer' }} onMouseOver={e => e.currentTarget.style.textDecoration = 'underline'}
                  onMouseOut={e => e.currentTarget.style.textDecoration = 'none'}>Google Cloud Fundamentals: Core Infrastructure Certificate</h5>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }

export default Achievements;
