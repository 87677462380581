import React from 'react';
import socials from "../data/socials";

function Contact() {
    return (
        <div>
            <h3 className='contact-title'>Let's Reach Out</h3>
            <p className='contact-description'>
                Don't be a stranger, especially now that you've scrolled this far! I'm always open to hearing about new opportunities,
                whether it's a new project, job, or just a friendly chat. Feel free to drop by any of the platforms below.
            </p>
            <footer>
                <div id="footer__left">
                    <code>
                        <span style={{ display: "inline", color: "cyan" }}>~/website-v4</span> on
                        <span style={{ display: "inline", color: "fuchsia" }}>main</span>
                    </code>
                    <code>
                        <span style={{ display: "inline", color: "lightgreen" }}>%</span> cat my-contact.txt
                    </code>
                    <code>Email: <a href={`mailto:${socials.email}`} style={{ color: 'lightblue' }}> {socials.email}</a></code>
                    <code>GitHub: <a href={socials.github} target="_blank" rel="noopener noreferrer" style={{ color: 'lightblue' }}>github.com/{socials.githubUsername}</a></code>
                    <code>LinkedIn: <a href={socials.linkedin} target="_blank" rel="noopener noreferrer" style={{ color: 'lightblue' }}>linkedin.com/in/{socials.linkedinUsername}</a></code>
                    <code>
                        Made with &lt;3 by <a href="/#connect" style={{ color: 'lightblue' }}>SwanHtetAung</a>. 
                    </code>
                </div>
              
            </footer>
        </div>
    );
}
export default Contact;