import React, { useEffect } from "react";
// This is the theme for the code highlighting

function CodeSnippet() {
  return (
    <div className="code-editor">
      <div className="window-header">
        <div className="window-controls">
          <div className="window-control close"></div>
          <div className="window-control minimize"></div>
          <div className="window-control maximize"></div>
        </div>
        <div className="file-name">/index.tsx</div>
      </div>
      <pre style={{ backgroundColor: "#10172A" }}>
        <code className="language-ts">
          <span className="keyword">import</span> {"{"} <span className="constant"> FC </span> {"}"} from{" "}
          <span className="string">"react"</span>;
          <br />
          <br />
          <span className="keyword">type</span> <span className="class-name">WelcomeProps </span>= {"{"}
          <br />
          &nbsp;&nbsp;uses:
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;|{" "}
          <span className="string">"explore new tech"</span>
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;|{" "}
          <span className="string">"display my skills"</span>
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;|{" "}
          <span className="string">"find freelancing opportunities"</span>
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;|{" "}
          <span className="string">"find an internship"</span>;
          <br />
          {"}"};
          <br />
          <br />
          <span className="keyword">export const</span> Welcome:{" "}
          <span className="constant">FC </span>
          {/* <span className="punctuation">&lt;</span> */}
          &lt;WelcomeProps&gt; = ({"{"}  uses  {"}"}) =&gt; {"{"}
          <br />
          &nbsp;&nbsp;return (
          <br />
          <span className="punctuation">&nbsp;&nbsp;&nbsp;&nbsp;&lt;&gt;</span>
          <br />
          <span className="punctuation">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;h1&gt;</span>This is my little slice
          of the internet.<span className="punctuation">&lt;/h1&gt;</span>
          <br />

          <span className="punctuation">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;p&gt;</span>
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I use this site to 
           <span className="punctuation"> &lt;em&gt;</span>{"{"}uses{"}"}<span className="punctuation">&lt;em&gt;</span>.
          <br />
          <span className="punctuation"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&lt;/p&gt;</span>
          <br />
          <span className="punctuation">&nbsp;&nbsp;&nbsp;&nbsp;&lt;/&gt;</span>
          <br />
          &nbsp;&nbsp;);
          <br />
          {"}"};
          <br />
          <br />
          <span className="keyword">export default</span> Welcome;
        </code>
      </pre>
    </div>
  );
}

export default CodeSnippet;
