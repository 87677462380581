import React from "react";
import NavBar from "./components/NavBar";
import Home from "./components/Home";
import About from "./components/About";
import Project from "./components/Project"
import Achievements from "./components/Achievements";
import Contact from "./components/Contact";

function App() {
  return (
    <div className="App">
      <NavBar />
      <section id="home">{<Home />}</section>
      <section id="about">{<About />}</section>
      <section id="projects">{<Project />}</section>
      {/* <section id="services">Services Section</section> */}
      <section id="achievements">{<Achievements/>}</section>
      <section id="contact">{<Contact/>}</section>
    </div>
  );
}

export default App;
