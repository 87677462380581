import React, { useState, useEffect } from "react";
import CodeSnippet from "./CodeSnippet";

const quotes = [
  "“No Hugging, Only Debugging”",
  "“No Date, Only Update”",
  "“No Crush, Only Crash”",
  "“No Romance, Only Maintenance”",
  "“No Smile, Only Compile”",
  "“No rest, Only Test”",
];

function getRandomGradient() {
  const colors = [
    "#ff6a00",
    "#ee0979",
    "#56ab2f",
    "#a8e063",
    "#2196f3",
    "#f44336",
  ];
  const randomIndex1 = Math.floor(Math.random() * colors.length);
  let randomIndex2 = Math.floor(Math.random() * colors.length);
  while (randomIndex1 === randomIndex2) {
    randomIndex2 = Math.floor(Math.random() * colors.length);
  }
  return `linear-gradient(90deg, ${colors[randomIndex1]}, ${colors[randomIndex2]})`;
}

function Home() {
  const [quoteIndex, setQuoteIndex] = useState(0);
  const [gradient, setGradient] = useState(getRandomGradient());
  const [key, setKey] = useState(Math.random());

  useEffect(() => {
    const interval = setInterval(() => {
      setQuoteIndex((prevIndex) => (prevIndex + 1) % quotes.length);
      setGradient(getRandomGradient());
      setKey(Math.random());
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="home-content">
      <div className="info">
        <p style={{ color: "#0BA5E9", fontWeight: "600" }}>
          Welcome to my site
        </p>
        <h1>
          <span style={{ fontSize: "4rem" }}>I'm </span>
          <span className="name">Swan Htet Aung</span>
        </h1>
        <h2 className="tagline">
          Software Engineer 💻 | Student 🧑‍💻 | Passionate Problem Solver
        </h2>
        <p className="description">
          I am a student and software engineer based in Singapore with a passion
          for crafting meaningful and user-centric experiences. Whether it's
          turning caffeine into code or classroom theories into real-world
          impact, I'm all about breathing life into ideas with a dash of
          creativity and a byte of logic.
        </p>

        <div className="button-container">
          <button id="letWork">Let's Work</button>
          <button id="resume">Resume</button>
        </div>

        <div className="quote-container">
          <div
            key={key}
            className="quote"
            style={{
              background: gradient,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              backgroundClip: "text",
              color: "transparent",
            }}
          >
            {quotes[quoteIndex]}
          </div>
        </div>
      </div>

      <div className="code-snippet">
        <CodeSnippet
          code={`import { FC } from "react";

// Type for props
type WelcomeProps = {
  uses:
      | "explore new tech"
      | "display my skills"
      | "find freelancing opportunities"
      | "find a fulltime job";
};

export const Welcome: FC<WelcomeProps> = ({ uses }) => {
  return (
      <>
          <h1>This is my little slice of the internet.</h1>
          <p>
              I use this site to <em>{uses}</em>.
          </p>
      </>
  );
};

export default Welcome;`}
        />
      </div>
    </div>
  );
}

export default Home;
